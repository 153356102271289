var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"sg-web-content-2"},[_c('img',{staticClass:"sg-w100",attrs:{"src":"https://zhengqizhixing.oss-cn-beijing.aliyuncs.com/images/star.png"}}),_c('el-row',{staticClass:"sg-border-bottom"},[_c('el-col',{attrs:{"sm":{ span: 16, offset: 4 }}},[_c('div',{staticClass:"sg-flex-center"},[_c('div',{staticClass:"sg-space-between sg-bold sg-gray-b1 sg-font-14px",staticStyle:{"width":"1152px"}},[_c('div',{staticClass:"sg-flex-center"},[_c('i',{staticClass:"el-icon-s-home sg-orange"}),_c('i',{staticClass:"el-icon-caret-right sg-pad",staticStyle:{"padding":"0 11px"}}),_c('div',[_vm._v("关于正启之星")]),_c('i',{staticClass:"el-icon-caret-right sg-pad",staticStyle:{"padding":"0 11px"}}),_c('div',[_vm._v("新闻中心")])]),_c('div',{staticClass:"sg-flex-center sg-gray-6a"},_vm._l((_vm.tabs),function(item,index){return _c('div',{key:index,class:{
                'sg-tab sg-pointer sg-hover sg-nav-ani': true,
                'sg-selected': _vm.tab == item.key,
              },style:({
                'margin-left': '30px',
                'line-height': _vm.scrollNavDown ? '80px' : '110px',
                height: _vm.scrollNavDown ? '82px' : '112px',
              }),on:{"click":function($event){return _vm.goTab(item.key)}}},[_vm._v(" "+_vm._s(item.name)+" ")])}),0)])])])],1),_c('el-row',[_c('el-col',{attrs:{"sm":{ span: 16, offset: 4 }}},[_c('div',{staticClass:"sg-flex-center"},[_c('div',{staticStyle:{"width":"1152px"}},[_c('div',{staticClass:"sg-pad-top-md sg-pad-bottom-md sg-border-bottom sg-font-30px sg-gray-59504c"},[_c('div',[_vm._v(_vm._s(_vm.item.title))]),_c('div',[_c('div',{staticClass:"sg-margin-top sg-gray-ceced1 sg-font-13px"},[_vm._v(_vm._s(_vm._f("formatDate")(_vm.item.created_at)))])])]),_c('div',{staticClass:"sg-pad-top sg-gray-3c3c48b3 sg-font-14px sg-line-height-18",domProps:{"innerHTML":_vm._s(_vm.item.content)}})])])])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }