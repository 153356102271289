<template>
  <div class="sg-web-content-2">
    <img src="https://zhengqizhixing.oss-cn-beijing.aliyuncs.com/images/star.png" class="sg-w100" />
    <el-row class="sg-border-bottom">
      <el-col :sm="{ span: 16, offset: 4 }">
        <div class="sg-flex-center">
          <div
            class="sg-space-between sg-bold sg-gray-b1 sg-font-14px"
            style="width: 1152px"
          >
            <div class="sg-flex-center">
              <i class="el-icon-s-home sg-orange"></i>
              <i class="el-icon-caret-right sg-pad" style="padding: 0 11px"></i>
              <div>关于正启之星</div>
              <i class="el-icon-caret-right sg-pad" style="padding: 0 11px"></i>
              <div>新闻中心</div>
            </div>
            <div class="sg-flex-center sg-gray-6a">
              <div
                :class="{
                  'sg-tab sg-pointer sg-hover sg-nav-ani': true,
                  'sg-selected': tab == item.key,
                }"
                v-for="(item, index) in tabs"
                :key="index"
                @click="goTab(item.key)"
                :style="{
                  'margin-left': '30px',
                  'line-height': scrollNavDown ? '80px' : '110px',
                  height: scrollNavDown ? '82px' : '112px',
                }"
              >
                {{ item.name }}
              </div>
            </div>
          </div>
        </div>
      </el-col>
    </el-row>
    <el-row>
      <el-col :sm="{ span: 16, offset: 4 }">
        <div class="sg-flex-center">
          <div style="width: 1152px">
            <!-- <el-card class="box-card sg-margin-tb"> -->
            <div
              class="sg-pad-top-md sg-pad-bottom-md sg-border-bottom sg-font-30px sg-gray-59504c"
            >
              <div>{{ item.title }}</div>
              <!-- <ArticleTag :item="item.category"></ArticleTag> -->
              <div>
                <div class="sg-margin-top sg-gray-ceced1 sg-font-13px">{{ item.created_at | formatDate }}</div>
              </div>
            </div>
            <div
              v-html="item.content"
              class="sg-pad-top sg-gray-3c3c48b3 sg-font-14px sg-line-height-18"
            ></div>
            <!-- <CommentBox :id="item.id"></CommentBox> -->
            <!-- </el-card> -->
          </div>
        </div>
      </el-col>
    </el-row>
  </div>
</template>

<script>
// import ArticleTag from "@/components/Web/ArticleTag.vue";
// import CommentBox from "@/components/Web/CommentBox.vue";

export default {
  name: "Article",
  props: {},
  components: {
    // ArticleTag,
    // CommentBox
  },
  data: () => {
    return {
      tab: "news",
      tabs: [{ name: "观点文章", key: "news" }],
      item: {
        category: {},
      },
    };
  },
  computed: {},
  mounted() {
    this.getArticle();
    // this.addViewNum();
  },
  methods: {
    goLink(url) {
      window.location = url;
    },
    getArticle() {
      let id = this.$route.params.id;
      this.$util.getData2(this, "articles/detail", { id: id }, "item");
    },
    addViewNum() {
      let id = this.$route.params.id;
      this.$util.doAction2(this, "articles/addViewNum", { id: id });
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
@import "@/assets/scss/global.scss";
</style>
